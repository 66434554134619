import { Section } from "./section";
import "./team.scss";

export class Team extends Section {
    constructor() {
        super();
    }

    id = "team";
    name = "Nosso time";
    isVisible = false;
    element = () => {
        return <div className="container">
            <div className="header">
                <h2>Nosso time</h2>
                <h3>O ativo mais valioso da nossa empresa</h3>
            </div>
            <div className="team-container">
                <div className="team-member">
                    <div className="team-member-image">
                        <img src="/images/lucas-yamamoto.jpg" />
                    </div>
                    <h4>Lucas Yamamoto</h4>
                    <h5>Founder</h5>
                    <p>
                        <ul>
                            <li>Ex-diretor da área de Digital Assets do Banco BTG Pactual</li>
                            <li>Primeiro Tenente do Quadro de Oficiais Engenheiros da Aeronáutica</li>
                            <li>Graduado em Engenharia da Computação pelo Instituto Tecnológico de Aeronáutica (ITA)</li>
                        </ul>
                        <a href="https://www.linkedin.com/in/lucas-yamamoto/"><img src="/images/linkedin-icon.svg" /></a>
                    </p>
                </div>
                <div className="team-member">
                    <div className="team-member-image">
                        <img src="/images/ricardo-cavalieri.jpg" />
                    </div>
                    <h4>Ricardo Cavalieri</h4>
                    <h5>Founder</h5>
                    <p>
                        <ul>
                            <li>Ex-tesoureiro da mesa de Digital Assets do Banco BTG Pactual</li>
                            <li>Ex-head de análise e estratégia de ações do BTG Pactual digital</li>
                            <li>Graduado em Administração de Empresas pela Fundação Getúlio Vargas (FGV)</li>
                        </ul>
                        <a href="https://www.linkedin.com/in/ricardo-cavalieri-filho/"><img src="/images/linkedin-icon.svg" /></a>
                    </p>
                </div>
                <div className="team-member">
                    <div className="team-member-image">
                        <img src="/images/rogerio-motisuki.jpg" />
                    </div>
                    <h4>Rogério Motisuki</h4>
                    <h5>Founder</h5>
                    <p>
                        <ul>
                            <li>Ex-head de Tecnologia da área de Digital Assets do Banco BTG Pactual</li>
                            <li>Graduado em Engenharia da Computação pela Universidade de São Paulo (USP)</li>
                        </ul>
                        <a href="https://www.linkedin.com/in/rogerioyuuki/"><img src="/images/linkedin-icon.svg" /></a>
                    </p>
                </div>
            </div>
        </div>;
    }
}