import React from 'react';
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Section } from './sections';
import './app.scss';

interface AppProps {
	sections: Array<Section>;
}

const App = (props: AppProps) => {
	const [showMenu, setShowMenu] = React.useState<boolean>(false);
	const [hideTransforms, setHideTransforms] = React.useState<boolean>(false);

	const appHeaderRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		adjustHeaderDisplay();
		window.onscroll = adjustHeaderDisplay;
	}, []);

	const adjustHeaderDisplay = () => {
		const appHeader = appHeaderRef.current;
		if(appHeader != null) {
			const fraction = Math.min(window.scrollY / (window.innerHeight / 3), 1);
			const opacity = fraction * 0.85;
			const blur = fraction * fraction * 5;
			appHeader.style.backgroundColor = `rgba(31, 42, 37, ${opacity})`;
			//appHeader.style.borderBottom = `1px solid rgba(255, 255, 255, ${opacity})`;
			appHeader.style.borderBottom = `1px solid rgba(4, 228, 158, ${opacity})`;
			appHeader.style.backdropFilter = `blur(${blur}px)`;
		}
	}

	const toggleMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
		setShowMenu(oldShowMenu => !oldShowMenu);
		e.preventDefault();
	}

	const goToSecion = (section: Section) => {
		return () => {
			const elem = document.getElementById(section.id);
			if(elem != null) {
				setShowMenu(false);
				elem.scrollIntoView({behavior: "smooth"});
			}
		}
	}

	const goToApp = (e: React.MouseEvent<HTMLButtonElement>) => {
		window.open("https://app.passify.co/", "passify_app");
		e.preventDefault();
	}

	const filteredSections = props.sections.filter(section => section.isVisible);

	return (
		<div id="app">
			<header ref={appHeaderRef} id="app-header">
				<h1><img id="app-logo" src="/images/passify-dark.svg" alt="Passify" /></h1>
				<div id="menu">
					<button className="invisible" onClick={toggleMenu}><FontAwesomeIcon icon={faBars} /></button>
					<ul className={showMenu ? "show" : ""}>
						<li id="close-menu-button-container">
							<button className="invisible" onClick={toggleMenu}><FontAwesomeIcon icon={faX} /></button>
						</li>
						{filteredSections.map(section => <li key={`menu-item-${section.id}`} onClick={goToSecion(section)}>{section.name}</li>)}
						<li id="open-app-menu-item">
							<button className="launch-app" onClick={goToApp}>Abrir App</button>
						</li>
					</ul>
				</div>
			</header>
			<div id="app-body" className={showMenu ? "hide-transforms" : ""}>
				{filteredSections.map(section => <section key={`section-${section.id}`} id={section.id}><section.element /></section>)}
			</div>
			<footer>
			</footer>
		</div>
	);
}

export { App };
