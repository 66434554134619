import { Section } from "./section";
import "./pass.scss";

export class Pass extends Section {
    constructor() {
        super();
    }

    id = "pass";
    name = "Seu passe";
    isVisible = true;
    element = () => {
        return <div className="container">
            <div className="video-container">
                <div className="mobile">
                    <img src="/images/iphone-mockup.png" />
                    <video src="/videos/passify-qr-loop.mp4" autoPlay={true} loop={true} playsInline={true} muted={true}></video>
                </div>
            </div>
            <div className="header">
                <h2>Todos os seus passes<br />em um único QR Code</h2>
                <h3>Não use prints, eles não vão funcionar!</h3>
            </div>
        </div>;
    }
}