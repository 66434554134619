import { Section } from "./section";
import "./press.scss";

export class Press extends Section {
    constructor() {
        super();
    }

    id = "press";
    name = "Na mídia";
    isVisible = true;
    element = () => {
        return <div className="container">
            <div className="header">
                <h2>Na mídia</h2>
                <h3>Veja o que a imprensa falou da gente</h3>
            </div>
            <div className="press-container">
                <a href="https://exame.com/future-of-money/uso-de-nfts-como-ingressos-ganha-forca-para-evitar-falsificacao-e-cambismo/" target="_blank">
                    <div className="press-item">
                        <div className="article-image" style={{backgroundImage: "url('/images/exame-article.webp')"}}></div>
                        <div className="title">Uso de NFTs como ingressos ganha força para evitar falsificação e cambismo</div>
                        <img className="publisher-image" src="/images/logo-exame.svg" alt="Exame" />
                    </div>
                </a>
            </div>
        </div>;
    }
}