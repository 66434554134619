import React from "react";
import { Section } from "./section";
import "./splash.scss"

export class Splash extends Section {

    constructor() {
        super();
    }

    id = "splash";
    name = "Início";
    isVisible = true;
    element = () => {
        const [timer, setTimer] = React.useState<NodeJS.Timer>();
        const [initialized, setInitialized] = React.useState<boolean>();
        const [textCursor, setTextCursor] = React.useState<number>(9);
        const [currentText, setCurrentText] = React.useState<number>(0);

        const texts = ["sua vida", "seu show", "sua festa", "seu futebol", "sua viagem"];
        const typeDelay = 100;
        const changeDelay = 2000;

        React.useEffect(() => {
            setCurrentText(0);
            setInitialized(true);
            setTextCursor(texts[currentText].length);
        }, []);

        React.useEffect(() => {
            if(initialized) {
                if(currentText >= 0 && currentText < texts.length) {
                    if(timer != undefined) {
                        clearInterval(timer);
                    }
                    setTimer(setInterval(() => {
                        setTextCursor(cursor => {
                            if(cursor < texts[currentText].length) {
                                return cursor + 1;
                            }
                            return texts[currentText].length;
                        });
                    }, typeDelay));
        
                    return () => {
                        if(timer != undefined) {
                            clearInterval(timer);
                        }
                    }
                } else {
                    setCurrentText(0);
                }
            }
        }, [currentText]);

        React.useEffect(() => {
            if(initialized) {
                if(textCursor >= texts[currentText].length) {
                    if(timer != undefined) {
                        clearInterval(timer);
                    }
                    setTimeout(() => {
                        setTimer(setInterval(() => {
                            setTextCursor(cursor => {
                                if(cursor > 0) {
                                    return cursor - 1;
                                }
                                return 0;
                            });
                        }, typeDelay));
                    }, changeDelay);

                    return () => {
                        if(timer != undefined) {
                            clearInterval(timer);
                        }
                    }
                } else if(textCursor <= 0) {
                    setCurrentText(oldCurrentText => oldCurrentText + 1);
                }
            }
        }, [textCursor]);

        const goToApp = (e: React.MouseEvent<HTMLButtonElement>) => {
            window.open("https://app.passify.co/", "passify_app");
            e.preventDefault();
        }

        return <div className="overlay">
            <div className="container">
                <div className="title">
                    <h2>Seu passe para<br /><span className="primary">{textCursor > 0 ? texts[currentText].substring(0, Math.min(textCursor, texts[currentText].length)) : ""}<span className="blink">_</span></span></h2>
                    <div className="launch-app-container"><button className="launch-app" onClick={goToApp}>Abrir App</button></div>
                </div>
            </div>
        </div>;
    }
}